'use client';

import React, { useEffect, useMemo } from 'react';
import Link from 'next/link';
import { ProductsFragment } from '@/gql';
import ProductTile from '@/components/ProductTile';
import NavComponent from '@/components/NavComponent';
import { useActiveCollection } from '@/hooks/use-active-collection';
import { useCollectionsNavigation } from '@/hooks/use-collections-navigation';
import { useProductKeyFactory } from '@/hooks/use-product-key-factory';
import { useStoreData } from '@/hooks/use-store-data';
import Pagination from '@/components/Pagination';
import analytics from '@/lib/analytics';
import styles from './index.module.css';

export interface CollectionProductsProps {
  collectionProducts: Record<string, ProductsFragment>;
  collectionProductsContainerClassName?: string;
  collectionSlug: string;
  pagination?: {
    page: number;
    pages: number;
  };
}

const CollectionProducts: React.FC<CollectionProductsProps> = ({
  collectionProducts,
  collectionSlug,
  collectionProductsContainerClassName = '',
  pagination,
}) => {
  // Set the current collection slug as active
  useActiveCollection(collectionSlug);
  // Get navigation friendly collection data from the ether (note that collectionProducts)
  // input may not exactly match this data as they may come from different sources)
  const collectionsNavigation = useCollectionsNavigation(false);
  const { page: paginationPage, pages: paginationPages } = pagination ?? { page: 1, pages: 1 };
  const productKeyFactory = useProductKeyFactory();

  const { storeData } = useStoreData();

  useEffect(() => {
    analytics.track('collection_page', { collectionName: collectionSlug }, storeData);
  }, [storeData, collectionSlug]);

  // Get the root collection for the collection we are displaying (the nav collection or
  // it's child contains the collection indicated by collectionSlug)
  const currentRootCollection = useMemo(() => {
    const mainCollection = collectionsNavigation.collections.find((collection) => collection.slug === collectionSlug);
    return (
      mainCollection ||
      collectionsNavigation.collections.find((collection) =>
        collection.collections?.some((item) => item.slug === collectionSlug)
      )
    );
  }, [collectionSlug, collectionsNavigation.collections]);

  // show nav component if collectionSlug is a root/base collection with children
  const isRootCollectionSlug = currentRootCollection?.slug === collectionSlug;

  return (
    <section aria-labelledby='product-list' className={collectionProductsContainerClassName}>
      <div className={styles.mainContainer}>
        {isRootCollectionSlug && (
          <div className={styles.headerContainer}>
            <NavComponent
              collectionsNavigation={{
                collections: [currentRootCollection!],
                activeSlug: collectionSlug,
              }}
              navMainClass={styles.navMain}
              ariaLabel='Collection navigation'
              collectionLinkClass={styles.headerTitle}
              subCollectionClass={styles.navContent}
              subCollectionActiveClass={styles.collectionActive}
              subCollectionDefaultClass={styles.collectionDefault}
              placement='collection_pill_subcollection'
            />
          </div>
        )}
        {Object.entries(collectionProducts).map(([slug, products]) => {
          const subCollectionTitle = currentRootCollection?.collections?.find((coll) => coll.slug === slug);
          const mainCollectionProducts = products.products || [];
          const showViewAll = isRootCollectionSlug && Number(products.total_count) > Number(products.count);

          return (
            <div key={slug} className={styles.productTileContent}>
              <h3>{subCollectionTitle?.name}</h3>
              <div className={styles.productGrid}>
                {mainCollectionProducts.map((product) => (
                  <ProductTile key={productKeyFactory(product)} product={product} />
                ))}
              </div>
              {showViewAll && (
                <div className={styles.viewAllContent}>
                  <Link href={slug} className={styles.collectionDefault}>
                    View All
                  </Link>
                </div>
              )}
            </div>
          );
        })}
      </div>
      {pagination && <Pagination currentPage={paginationPage} totalPages={paginationPages} />}
    </section>
  );
};

export default CollectionProducts;
