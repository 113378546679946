'use client';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import CollectionProducts, { CollectionProductsProps } from '@/components/CollectionProducts';
import { useEnableNavUnderline } from '@/hooks/use-enable-nav-underline';
import { usePreviewStoreTheme } from '@/hooks/use-preview-store-theme';
import { ToastErrorType, isToastErrorType } from '@/lib/toast-errors';
import { redirect } from 'next/navigation';

export type Props = CollectionProductsProps & {
  collectionProductsPropsFactory?: (previewMode?: boolean) => Promise<CollectionProductsProps | ToastErrorType>;
};

/**
 * Products wrapper with support for pulling preview products
 *
 * @param props
 * @returns
 */
const PreviewCollectionProductsShim = (props: Props) => {
  // memo-ize divided props for variable stability
  const [collectionProductsPropsFactory, passThroughProps] = useMemo(() => {
    const { collectionProductsPropsFactory: factory, ...rest } = props;
    return [factory, rest];
  }, [props]);
  const [collectionProductsProps, setCollectionProductsProps] = useState(passThroughProps);
  const [activeRedirectUrl, setActiveRedirectUrl] = useState<string>('');

  // Enable nav top underlines
  useEnableNavUnderline();

  // Use immediate mode to get the value on the first call (as opposed to being defered
  // to get past the first client side render) as we handle that ourselves by consuming
  // it in a useEffect()
  const previewStoreTheme = usePreviewStoreTheme(true /* immediate mode */);
  const previewMode = Boolean(previewStoreTheme?.preview);

  const inFlight = useRef(false);
  useEffect(() => {
    // If this is preview mode (and a props factory was provided), request the preview products
    if (previewMode && collectionProductsPropsFactory) {
      if (!inFlight.current) {
        inFlight.current = true;
        collectionProductsPropsFactory(previewMode)
          .then((result) => {
            if (isToastErrorType(result)) {
              setActiveRedirectUrl(`/?err=${result}`);
            } else {
              setCollectionProductsProps(result);
            }
          })
          .finally(() => {
            inFlight.current = false;
          });
      }
    } else {
      setCollectionProductsProps(passThroughProps);
    }
  }, [collectionProductsPropsFactory, passThroughProps, previewMode]);

  // We could skip rendering for the first frame until we know if we're waiting for preview
  // products, but for now I'm working under the assumtion than the quicker load for the
  // 99.99% usage (regular store) is better than a product shuffle in preview in the off
  // chance there are extra products in preview mode

  if (activeRedirectUrl) {
    return redirect(activeRedirectUrl); // should throw
  }

  return Object.keys(collectionProductsProps?.collectionProducts).length ? (
    <CollectionProducts {...collectionProductsProps} />
  ) : null;
};

export default PreviewCollectionProductsShim;
